import React from "react"
import Layout from "../components/Layout/Layout"
import classes from "../styles/404.module.css"

const error = () => {
  return (
    <Layout>
      <div className={classes.main}>
        <div className={classes.error}>404</div>
        <div className={classes.message}>Oops,page not found...</div>
      </div>
    </Layout>
  )
}

export default error
